import axios from "axios";


// let BaseUrl = "http://localhost:7300/"
let BaseUrl = process.env.VUE_APP_API_URL + "/";



function getConversation(){
    return axios.get(BaseUrl+'conversation')
}

function getMessages(){
    return axios.get(BaseUrl+'message/1')
}

function getNewTask(){
    return axios.get(BaseUrl+ 'task/moderator')
}

function getDepartment(){
    return axios.get(BaseUrl+ 'expert/department');
}

function getOwnDetail(){
    return axios.get(BaseUrl+'expert/own-detail');
}

function getTasks(){
    return axios.get(BaseUrl+ 'task/expert');
}



export default {
    getMessages,
    getConversation,
    getNewTask,
    getDepartment,
    getOwnDetail,
    getTasks
}