

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index';
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'



Vue.config.productionTip = false

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll);

import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'

import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
 
Vue.use(DatetimePicker)


import interceptor from "./api/interceptor";
  interceptor();


window.toastr = require('toastr')
Vue.use(VueToastr2);


import AuthApi from "./api/auth";
Vue.prototype.$Auth = AuthApi;

import GeneralApi from "./api/general";
Vue.prototype.$General = GeneralApi;

import ExpertApi from "./api/expert";
Vue.prototype.$Expert = ExpertApi;

import DesignApi from "./api/design";
Vue.prototype.$Design = DesignApi;

import MessageApi from "./api/message";
Vue.prototype.$MessageApi = MessageApi;

import FileApi from "./api/file";
Vue.prototype.$FileApi = FileApi;

import DashbaordApi from "./api/dashboard";
Vue.prototype.$DashboardApi = DashbaordApi;

export const bus = new Vue();

import moment from 'moment';
  Vue.mixin({
    methods: {
        formatDate(value,format){
          let setFormat = format?format:"MMM Do YYYY"
            if (!value) return ''
        value = moment(value).format(setFormat);
        return value;
    
          },
          hasRole(parentRole, subRole){
            let found = store.getters.navigationDetails.find(x=>x.parentRoleName ==parentRole);
            if(found){
              let subRoles = found.subRoles
            let index = subRoles.indexOf(subRole);
            return index!=-1?true:false;
            }
            return false;
          },
          hasAccess(parentRole){
            let found = store.getters.navigationDetails.find(x=>x.parentRoleName ==parentRole);
            if(found){
              return true;
            }else{
              return false;
            }
          }
    }
  })

  let test = 'localhost:3000/qna'

//   import VueNativeSock from 'vue-native-websocket'
// Vue.use(VueNativeSock, "ws://" + test ,{debug:true, path:'/qna/'})


// working code

import VueSocketIO from 'vue-socket.io';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';


let socketUrl = process.env.VUE_APP_SOCKET_URL;
// let socketUrl = "http://localhost:3000/qna";

const socket = io(socketUrl, {
  autoConnect: false,
  extraHeaders: {
    Authorization: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN0cmluZyIsImlkIjoiMSIsInR5cGUiOiJjdXN0b21lciIsImlhdCI6MTY0OTE3NTU5MywiZXhwIjoxNjU3ODE1NTkzfQ.VSiuNfQPTbqx2rpYBq8flU5ui-y6mfYj5THvwwxNGi0"
  } 
});

Vue.use(VueSocketIOExt, socket);


// ace

// const options = { 
//   transportOptions: {
//     polling: {
//       extraHeaders: {
//         Authorization: `Bearer ${token}`
//       }
//     }
//   },

// Vue.use(VueSocketIOExt({
//     debug: true,
//     connection: io(test, {
//       extraHeaders: {
//         Authorization: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN0cmluZyIsImlkIjoiMSIsInR5cGUiOiJjdXN0b21lciIsImlhdCI6MTY0OTE3NTU5MywiZXhwIjoxNjU3ODE1NTkzfQ.VSiuNfQPTbqx2rpYBq8flU5ui-y6mfYj5THvwwxNGi0"
//       },
//       transport: ['websocket'],
//     }),
 

// }))

import AppFilter from "./views/dashboard/component/AppFilter.vue"

Vue.component('app-filter',AppFilter)




new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
