

const state = {
    taskDetails: {},
    activeChat: null,
 }
 
 
 const mutations = {
    setTaskDetails(state,payload){
        state.taskDetails = payload;
    },
    setCurrentActiveChat(state, payload){
        state.activeChat = payload;
    }
 }
 
 const actions = {
 
 }
 
 const getters = {
  taskDetails: (state)=>state.taskDetails,
  activeChat: (state)=>state.activeChat
 }
 
 // export this module.
 export default {
     state,
     mutations,
     getters,
     actions
 }
 