import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/';

function getVideoConference(options){
    if(!options.search){
        options.search = ''
    }
    return axios.get(BaseUrl+'video-conference/expert?page='+options.page+'&size='+options.itemsPerPage+'&search='+options.search+'&isDeleted='+options.isDeleted);
}

function getVideoConferenceById(id){
    return axios.get(BaseUrl+'video-conference/expert/'+ id)
}

// function approveConference(data){
//     return axios.put(BaseUrl+'video-conference/'+'approve',data);
// }

function conferenceCallCompleted(id){
    return axios.put(BaseUrl+'video-conference/'+ 'called/'+id);
}



function getOnSiteConsultation(page=1,size=10){
    return axios.get(BaseUrl+'on-site-consultation/expert?page='+page+'&size='+size);
}

function getOnSiteConsultationById(id){
    return axios.get(BaseUrl+'on-site-consultation/expert/'+ id)
}

function approveOnSiteConsultation(data){
    return axios.put(BaseUrl+'on-site-consultation/'+'approve',data);
}

function onSiteConsultationCompleted(id){
    return axios.put(BaseUrl+'on-site-consultation/'+ 'visited/'+id);
}





export default {   
    getVideoConference,
    getVideoConferenceById,
    // approveConference,
    conferenceCallCompleted,
    getOnSiteConsultation,
getOnSiteConsultationById,
approveOnSiteConsultation,
onSiteConsultationCompleted,
   
}