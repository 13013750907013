import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  //this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (localStorage.getItem("loggedInUser")) isAuthenticated = true;
  else isAuthenticated = false;

  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/auth/sign-in"); // go to '/login';
  }
}

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      beforeEnter: guardMyroute,
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/views/dashboard/Dashboard"),
        },
        {
          name: "Design",
          path: "/design",
          component: () => import("@/views/dashboard/Design/Design"),
        },
        {
          name: "Qna",
          path: "/qna",
          component: () => import("@/views/dashboard/Qna"),
        },
        {
          name: "Design Details",
          path: "/design/:id",
          component: () => import("@/views/dashboard/Design/DesignDetails"),
        },
        {
          name: "Messages",
          path: "message",
          component: () => import("@/views/dashboard/Message/Messages.vue"),
        },
        {
          name: "Messages",
          path: "message/:id",
          component: () => import("@/views/dashboard/Message/Messages.vue"),
        },
        {
          name: "Change Password",
          path: "change-password",
          component: () => import("@/views/dashboard/ChangePassword"),
        },
        {
          name: "Conference Call",
          path: "conference-call",
          component: () => import("@/views/dashboard/ConferenceCall"),
        },
        {
          name: "Conference Call Details",
          path: "conference-call/:id",
          component: () => import("@/views/dashboard/ConferenceCallDetails"),
        },
        {
          name: "On Site Consultation",
          path: "on-site-consultation",
          component: () => import("@/views/dashboard/OnSiteConsultationList"),
        },
        {
          name: "On Site Consultation Details",
          path: "on-site-consultation/:id",
          component: () =>
            import("@/views/dashboard/OnSiteConsultationDetails"),
        },
        // Pages
        {
          name: "User Profile",
          path: "pages/user",
          component: () => import("@/views/dashboard/pages/UserProfile"),
        },
        {
          name: "Notifications",
          path: "components/notifications",
          component: () => import("@/views/dashboard/component/Notifications"),
        },
        {
          name: "Icons",
          path: "components/icons",
          component: () => import("@/views/dashboard/component/Icons"),
        },
        {
          name: "Typography",
          path: "components/typography",
          component: () => import("@/views/dashboard/component/Typography"),
        },
        // Tables
        {
          name: "Regular Tables",
          path: "tables/regular-tables",
          component: () => import("@/views/dashboard/tables/RegularTables"),
        },
        // Maps
        {
          name: "Google Maps",
          path: "maps/google-maps",
          component: () => import("@/views/dashboard/maps/GoogleMaps"),
        },
        // Upgrade
        {
          name: "Upgrade",
          path: "upgrade",
          component: () => import("@/views/dashboard/Upgrade"),
        },
      ],
    },
    {
      path: "/auth",
      component: () => import("@/views/Auth/Index.vue"),
      children: [
        {
          name: "Sign In",
          path: "sign-in",
          component: () => import("@/views/Auth/Signin"),
        },
        {
          name: "Forgot Password",
          path: "forgot-password",
          component: () => import("@/views/Auth/ForgotPassword"),
        },
        // {
        //   name: 'Sign Up',
        //   path: 'signUp',
        //   component: () => import('@/views/Auth/Signup'),
        // },
        // {
        //   name: 'Forgot Password',
        //   path: 'forgot-password',
        //   component: () => import('@/views/Auth/ForgotPassword'),
        // }
      ],
    },
    { path: "*", component: () => import("@/views/PageNotFound") },
  ],
});
