import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/dashboard/';


function getDashboard(){
    return axios.get(BaseUrl+ 'expert');
}








export default {
    getDashboard
  
   
}