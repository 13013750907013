import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/design/';

function getDesignList(options){
    console.log("op", options)
    if(!options.search){
        options.search = ''
    }
    return axios.get(BaseUrl+ '?page='+options.page+'&size='+options.itemsPerPage+'&search='+options.search+'&isDeleted='+options.isDeleted);
}

function getDesignById(id){
    return axios.get(BaseUrl+ id);
}

function approveDesign(data){
    return axios.put(BaseUrl+ 'approve',data);
}

function disapproveDesign(data){
    return axios.put(BaseUrl+ 'disapprove',data);
}

function uploadMeasurement(data){
    return axios.put(BaseUrl+ 'upload/measurements',data);
}

function uploadSampleDesign(data){
    return axios.put(BaseUrl+ 'upload/sample-design',data);
}

function uploadRenderView(data){
    return axios.put(BaseUrl+ 'upload/render-view',data);
}

function uploadDetailedDesign(data){
    return axios.put(BaseUrl+ 'upload/detailed-design',data);
}


export default {   
    getDesignList,
getDesignById,
approveDesign,
disapproveDesign,
uploadSampleDesign,
uploadRenderView,
uploadDetailedDesign,
uploadMeasurement
   
}