import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/expert/';




function signIn(data){
    return axios.post(BaseUrl + "sign-in",data);
}

function signUp(data){
    return axios.post(BaseUrl + "client/sign-up",data);
}

function resetPassword(email){
    return axios.post(BaseUrl + "client/forgot/"+email)
}

function changePassword(data){
    return axios.put(BaseUrl + "change-password",data);
}

function forgotPassword(email){
    return axios.get(BaseUrl + "client/forgot/"+email);
}

function getOwnDetail(){
    return axios.get(BaseUrl+ 'own-detail');
}




export default {
    signIn,
    signUp,
    resetPassword,
    changePassword,
    forgotPassword,
    getOwnDetail
}