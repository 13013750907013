import axios from 'axios';

let BaseUrl = process.env.VUE_APP_FILE_URL;


function uploadFile(data){
    return axios.post(BaseUrl+ '/expert',data);
}


export default{
    uploadFile
}